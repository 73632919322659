@import "../../App";

.question-main {
    display: flex;
    background-color: $color-white;
    transition: all ease-in-out;
    transition-duration: 200;
    height: 100%;
    animation: slide 2s forwards;

}


.delete-popover {
    margin-top: 160px;
    margin-left: 0px;
    cursor: pointer;


    :hover {
        color: red;
    }
}

.question-delete-main {
    display: flex;
    gap: 40px;
    padding: 12px 16px;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1) !important;
    .question-delete-text{
        cursor: pointer;
        padding: 4px 0px;
    }

    p {
        @include font-style($font-main, 12px, 400, 16px);
    }


    svg {
        width: 18px !important;
    }
}

.question-inside {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    position: relative;

    form {
        background-color: $color-white;
        padding: 12px 16px;
        gap: 12px;
        display: flex;
        flex-direction: column;

        .MuiLoadingButton-root {
            background: $button-enabled-color;
            width: 100%;
            color: $color-white;
            font-size: 12px;
            padding: 12px 0;
        }

        .disb {
            background: $button-disabled-color !important;
            //color: $whiteColor !important;
        }
        .ask-question-field{
            position: relative;
            .asq-helper-absolute{
                position: absolute;
                top: 10px;
                right: 10px;
                @media (min-width: mediaWight(mobileXl)) {
                    display: none;
                }
            }
        }

    }
}

.question-title-main {
    padding: 16px 20px;
    display: flex-end;
    background-color: $child-titleColor;
    color: $child-titleTextColor;

    .question-title {
        width: 100%;
        text-align: center;
        @include flex(space-between, center);
        @include font-style($font-second, 700, 26px, 28px);
    }
}

.question-cards-main {
    @include flex(content, center);
    flex-direction: column;
    align-items: end;
    flex-grow: 1;
    gap: 10px;
    overflow: overlay;
    padding: 8px 16px;
    scroll-behavior: smooth;

    .question {
        background-color: $color-cards-background;
        padding: 12px 16px 16px 16px;
        border-radius: 8px 0 8px 8px;



        .question-text {
            @include font-style($font-main, 400, 15px, 150%);
        }

        .answered-time {
            @include font-style($font-main, 400, 12px, 100%);
            @include flex(right, center);
            gap: 12px;
            margin-top: 8px;
            color: #787e83;

            .answered {
                //background-color: #12c18d;
                border-radius: 4px;
                color: $color-white;
                padding: 6px 12px 4px;
                @include font-style($font-main, 400, 10px, 100%)
            }

        }
    }
}

.dot {
    text-align: right;
    cursor: pointer;

    svg {
        margin-top: auto;
        margin-bottom: 4px;
    }
}

.guideline {
    @include font-style($font-main, 400, 12px, 22px);
    color: $color-message-color;
}

@media (max-width: 1024px)  {
    .question-cards-main{
        height: auto;
        overflow-y: scroll;
        max-height: 400px;
        min-height: 200px;
    }
}
@media (min-width: 1024px) and (max-width: 1768px) {

    .question-cards-main {
        .question {
            .question-text {
                @include font-style($font-main, 400, 14px, 140%);
            }

            .answered-time {
                @include flex(right, center);
                gap: 12px;
                margin-top: 8px;

                .answered {
                    border-radius: 4px;
                    color: $color-white;
                    padding: 6px 12px 4px;
                    @include font-style($font-main, 400, 11px, 100%)
                }

            }

        }
    }

}

@media (max-width: 767px) {
    .question-main {
        height: 100%;
        animation: slide 2s forwards;

    }

    .title-main {
        display: none;
    }
}

.modal-box {
    text-align: center;

    .modal-cancel-btn,
    .modal-delete-btn {
        cursor: pointer;
    }
}