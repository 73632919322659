@import "../../App";
@import "./Mui";
@import "../../Colors.scss";
@import "./media";



//Each  FC
@each$name, $glyph in $wight {
  .wight-percent-#{$name} {
    width: $glyph;
  }
  .minWight-#{$name} {
    min-width: $glyph;
  }
}

@each$name, $glyph in $colors  {
  .color-text-#{$name} {
    color: $glyph;
  }
  .bg-color-#{$name} {
    background: $glyph;
  }
}
// fonts
.agenda-font-speaker-title{
  @include font-style($font-title,500,20px,28px)
}
// todo Button
.btn-reset-style {
  background: none;
  border: none;
}

button:hover {
  cursor: pointer;
}

// todo message
.show-msg-detail {
  position: absolute;
  width: 140px;
  height: auto;
  background: hsl(220, 45%, 95%);
  box-shadow: 0 0 5px $color-black;
  display: flex;
  z-index: 2;
  flex-direction: column;
  align-items: flex-end;
}

.msg-detail-div {
  width: 100%;
  display: flex;
}

.chat-file-text-my-msg {
  text-decoration: none;
  color: white;
}

.chat-file-text-not-my-msg {
  text-decoration: none;
  color: $color-black;
}

.chat-msg-img {
  min-width: 120px;
  display: flex;
  height: 120px;
  background: $color-black;
  justify-content: center;
  width: fit-content;
  max-width: 200px;

  img {
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.show-msg-to-edit {
  position: absolute;
  top: -60px;
  background: $color-cards-background;
  width: 95%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  left: 2.5%;
  height: 54px;
  overflow: hidden;

  div p:last-child {
    margin-top: 20px;
  }
}

.go-to-last-msg {
  background: silver;
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: 100px;

}

.chat-main-public{
  .chat-header-avatar {

    .online-circle,
    .offline-circle{
      right: 0;
      bottom: 0;
      height:9px;
      width: 9px;
      box-shadow: 0 0 0 2px $color-white;
    }
  }
}
.chat-header-avatar {
  height: 100%;
  position: relative;
  .online-circle,
  .offline-circle{
    right: -4px;
    bottom: 4px;
  }

  img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
}

.emoji-div {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 240px;
  margin: 0 auto;
  width: 85%;
  @media screen and (max-width: 1250px) {
    width: fit-content;
    justify-content: start;
    margin-left: 20px;

  }
}

.emoji-div-edit {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 300px;
  margin: 0 auto;
  width: 85%;
  @media screen and (max-width: 1250px) {
    width: fit-content;
    justify-content: start;
    margin-left: 20px;

  }
}

.my-reaction {
  position: absolute;
  bottom: 0;
  left: 3px;
  cursor: pointer;
  width: 20px;
}

.not-my-reaction {
  position: absolute;
  bottom: 0;
  right: 3px;
  cursor: pointer;
  width: 20px;
}

//todo toast
.toast-container {
  display: flex;
}

.msg-toast {
  margin-top: 10px;
}

select,
textarea,
input {
  font-size: 16px;
  padding: 10px;
}
img {
  object-fit: cover;
}


//todo Sponsor
.sponsor-logo-add {
  display: flex;
  padding: 14px ;
  box-sizing: border-box;
  height: 15%;
  background: $color-white;
  color: $color-black;
  position: relative;
  justify-content: center;

  .circle-with-exclamation-mark {
    height: 12px;
    width: 12px;
    border-radius: 6px;
    border: 1px solid $color-background;
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .line-sponsor-add {
      height: 4.74px;
      width: 1.2px;
      background: #f8f8f8;
      margin-bottom: 1.14px;
    }

    .circle-sponsor-add {
      height: 1.6px;
      width: 1.6px;
      background: #f8f8f8;
      border-radius: 50%;
    }
  }

  .sponsor-add-wrap {
    p {
      @include font-style("Roboto Light", 600, 16px, 19px);
    }
  }

  .sponsor-header {
    display: flex;
    align-items: center;
    //width: fit-content;
    gap: 8px;
    margin-bottom: 8px;
    height: 100%;
    width: 100%;
    img{
      margin: 0 auto;
      cursor: pointer;
      height: 90%;
    }
  }

  .name-add {
    color: #1FD662;
    font-size: 24px;
    height: auto;
  }

  .text-add {
    @include font-style("Roboto Light", 600, 16px, 19px);

    .sponsor-img-div {
      width: 40px;
      height: 40px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

}

.sponsors-agenda {
  @include flex(center, center);
  max-height: 100px;
  height: auto;
  gap: 20px;
  z-index: 5;
  padding: 16px;
  background: $color-white;

  img{
    max-height: 100px;
  }

}

.wrap-add-with-child {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 100%    ;
}

//Headers
.svg-title {
  display: flex;
  justify-content: center;
  align-items: center;

  span{
    display: flex;
  }
  svg {

    width: 32px;
    margin-right: 25px;
  }
}

//Header layout stream
.title-main {
  padding: 23px 24px;
  height: $wight-title-layout-children;
  box-sizing: border-box;
  background-color: $color-background;
  color: $color-cards-background;

  div:first-child {
    @include flex(start, center);
    gap: 24px;
  }
  .title{
    p {
      @include font-style($font-title, fontWeight(bold), 22px, 27px);
      @include flex(center, center);
      svg{
        margin-top: 5px;
      }
    }

  }
}

//sizes
.h100 {
  height: 100%;
}
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 55;
  background: #000;
  @include flex(center, center);

}
.responsive-iframe {
  height: 100%;
  margin: 0 auto;
}
// Components
.presence-main {
  background-color: #F3F4F8;
  border-radius: 42px;
  padding: 0 6px 0 4px;
  min-width: 60px;
  gap: 4px;
  font-size: 13px;
  p{
    @include font-style($font-title, 400, 12px, 14px);
  }
  @include flex(center, center);
}

//colors
.text-color-gray-shade{
  color: $color-grey-shade;
}
//span
.span-black,
.span-white{
  font-weight: 600;
}
.span-black{
  color: black;
}
.span-white{
  color: $color-white;
}

//speakers
.event-agenda-speakers-text-container{
  color: #282828BF;
}
.event-agenda-speakers-text-wrap {
  display: flex !important;
  flex-direction: row !important;
  gap: 4px !important;
  @include font-style($font-third, 600, 14px, 24px);
}
.speaker-card {
  @include flex(none, center);
  gap: 20px;
  margin-top: 20px;
  span{
    color: $color-grey-shade;
  }


  img {
    width: 46px;
    height: 46px;

  }
  img,
  svg{
    border-radius: 50%;
    background:#F8F8F8;

  }
  svg{
    padding: 5px;
    height: 36px;
    width: 36px;
  }

  div {
    p:first-child {
      @include font-style($font-main, 500, 16px, 20px);
    }

    p {
      @include font-style($font-main, 400, 14px, 20px);
    }
  }

  .agenda-detail-viewall {
    @include font-style($font-main, 400, 14px, 14px)
  }
}
//chair
.chair-container-mobile{
  max-height: 500px;
  min-height:fit-content;
}
.chair-card-container {
  max-height: 600px;
  @include flex(none, center);
  gap: 16px;
  flex-wrap: wrap;
  width: auto;

  h2 {
    width: 100%;
    font-size: 20px;
    font-weight: 500;

  }
  svg{
    height: 20px;
    width: 20px;
  }
  .chair-card {
    display: flex;
    padding: 3px 8px 3px 8px;
    background:#F8F8F8;
    border-radius: 31px;
    color: $color-black-50;
    gap: 8px;
    @include flex(center, center);

    img,
    svg {
      border-radius: 50%;
    }
    svg{
      height: 28px;
      width: 28px;
    }
    @include font-style($font-third, 400, 14px, 24px);
    .placeholder_text-event-agenda{
      p{
        @include font-style($font-third, 400, 14px, 24px);

      }

      display: flex;
      @include flex(center, center);
      flex-direction: row;

    }
  }
}
.triangle{
  position: relative;

  &:after {
    content: '\25B6';
    top: -6px;
    right: 7px;
    color: $color-silver ;
    position: absolute;
    rotate: 270deg;
  }
}
//popover
.popover-contextHelp {
  margin-top: 10px;
  border: 2px solid $color-silver;
  border-radius: 12px;
  background: $color-white;
  padding: 24px;
  box-sizing: border-box;
  width: 300px;
  z-index: 60;
  @include flex(none, none, column);
  gap: 12px;
  position: relative;
  //&:after {
  //  content: '\25B6';
  //  top: -17px;
  //  right: 50px;
  //  color: $color-silver ;
  //  position: absolute;
  //  rotate: 270deg;
  //}

  .popover-contextHelp-box {
    @include flex(none, none, column);
    gap: 4px;
    p{
      @include font-style($font-second, fontWeight('meduim'), 14px, 21px);
    }

    &:not(:last-child) {
      border-bottom: 1px solid $color-black-50-rgba;
      padding-bottom: 12px;
    }
  }

  h4 {
    color: $color-silver;
    @include font-style($font-second, fontWeight('regular'), 14px, 14px);
  }

}


//Arrow
.arrow{
  color: $arrow-color;
}
@media (max-width: mediaWight('desktopSm')) {
  .sponsor-logo-add{
    .sponsor-header {

      width: 70%;
      margin: 0 auto;

      img{
        margin: 0 auto;
        cursor: pointer;
        width: 90%;
        height: 90%;
      }
    }
  }
}


