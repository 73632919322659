@import "../../App";

.body {
  background-color: $color-white;
}

.body::-webkit-scrollbar-thumb {
  background: #4c6fff;
}

.layout-main {
  @include flex(flex-end, none);
  transition: all ease-in-out;
  transition-duration: 200;
  height: 90vh;

  .stream-container {
    width: 100%;
    justify-content: center;

    .layout-event-stream {
      width: 100%;
      display: flex;
      height: 100%;
      background-color: $color-background;

      .no-video-container {
        width: 100%;
        display: flex;
        height: 90.85vh;
        color: rgb(176, 177, 179);
        justify-content: center;
        @include font-style($font-main, fontWeight('regular'), 16px, 24px);

        .content {
          margin: auto;
          text-align: center;
        }

        svg {
          width: 84px;
          fill: rgb(176, 177, 179);
        }
      }

      .container {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;

        iframe {
          //height: calc(100% - #{$wight-title-layout-children});
          background: black;
        }

        .player-container {
          @include flex(none, center);
          height: 100%;
          }

        .layout-header {
          height: $wight-title-layout-children;
          width: 100%;
          background: $child-titleColor;
          //padding: 24px 0;
          background: $color-three;
          color: $color_one;

          .layout-header-main {
            @include flex(space-between, none);
            padding: 0 50px;
            height: 100%;

            .layout-header-main-left {
              @include flex(center, center);

              .layout-header-title {
                height: 100%;
                h2 {
                  margin-bottom: 10px;
                }
                p {
                  font-size: 20px;
                }

                .speakers-header {
                  @include flex(start, center);
                  gap: 15px;
                  margin-bottom: 10px;
                  height: 100%;
                  @media (max-width: mediaWight(desktopSm)) {
                    margin: 0;
                  }

                  img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                  }
                  font-size: 20px;
                }
              }

              .layout-header-img {
                width: auto;
                height: 70px;
                @include flex(center, center);
                box-sizing: border-box;

                img {
                  border-radius: 50%;
                  height: 70px;
                  width: 70px;
                }

                .layout-header-img-border {
                  height: 40px;
                  margin: 0 20px;
                  border-right: 2px solid silver;

                }
              }
            }

            .layout-header-main-right {
              width: auto;
              box-sizing: border-box;
              display: flex;
              gap: 20px;
              align-items: center;

              .location-container {
                line-height: 30px;

                &:last-of-type(p) {
                  color: $color-grey-shade;
                }

                .layout-header-location {
                  @include flex(center, center);
                  gap: 10px;
                  font-size: 25px;
                  background-color: $color-blue;
                  //background-image: $button-gradient;
                  background-size: 100%;
                  -webkit-background-clip: text;
                  -moz-background-clip: text;
                  -webkit-text-fill-color: transparent;

                }
              }

              .layout-header-time {
                @include font-style($font-third, 700, 32px, 39px);
                @include flex(center, center);
              }
            }
          }
        }

        .layout-not-authorized {
          @include flex(center, center);
          background: white;
          padding: 20px;
          box-sizing: border-box;
        }
      }

      .responsive-iframe {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background-color: white;
      }
    }
  }

  .mobile-layout {
    @include flex(flex-end, none);
    position: relative;

    .layout-children {
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      background-color: $color-white !important;

      .cross-btn {
        position: absolute;
        top: 20px;
        z-index: 3;
        width: 36px;
        height: 36px;
        right: calc(22px + 78px);
        cursor: pointer;
      }

    }
  }

}

@media (min-width: mediaWight(desktopSm)) and (max-width: 1439px) {

  .layout-event-stream {
    height: 100%;

    //.container {
    //  max-width: 1090px;
    //}
  }

  .mobile-layout {
    .layout-children {
      .cross-btn {
        width: 30px;
        height: 30px;
        right: 24.5%;
      }
    }
  }

}

@media (min-width: 1921px) and (max-width: 2560px) {
  .layout-event-stream {
    .container {
      max-width: 2400px;
    }
  }

}


@media (max-width: 1224px) {
  .layout-header-title h2 {
    font-size: 17px;

  }
  .layout-event-stream .container .layout-header .layout-header-main .layout-header-main-right .location-container .layout-header-location {
    font-size: 17px;
    line-height: 17px;
    flex-direction: row-reverse;

  }

  .layout-children {
    height: 80%;
  }
}

@media (max-width: mediaWight(desktopSm)) {

  .layout-main {
    height: 100%;

    .stream-container {
      .layout-event-stream {
        .container {
          iframe{
            //height: 50vh;

          }
          height: fit-content;
          .layout-header {
            height: auto;
          }

          .layout-not-authorized {
            height: calc(100% - 184px);
          }
        }
      }
    }
  }
}

@media (min-width: mediaWight(desktopSm)) {

  .layout-children {
    height: 85%;

  }

}

@media (max-width: 1224px) {
  .layout-main .stream-container .layout-event-stream .container .layout-header .layout-header-main .layout-header-main-right .layout-header-time{
    font-size: 25px;
  }
}
@media (max-width: 1024px) {

  .layout-children {
    max-height: 900px;
    overflow: scroll;

  }
  .layout-main {
    .stream-container {
      .layout-event-stream {
        height: auto;


        .container {
          @include flex(none, none, column);
          background: black;
          iframe{
            margin: 0 auto ;
          }
          .layout-header {

            .layout-header-main {
              padding: 0 20px;

              .layout-header-main-left {
                width: 100%;

                .layout-header-title {
                  width: 100%;

                  .speakers-header {
                    align-items: flex-start;
                    min-height: $wight-title-layout-children;
                    height: auto;
                    gap: 10px;

                    & > p, svg, img {
                      align-self: center;
                    }

                  }
                }
              }
            }
          }
        }
      }
    }
  }
}



//all mobiles
@media (max-width: mediaWight(mobileXl)) {

  .layout-main {
    justify-content: flex-start;
    flex-direction: column;

    .stream-container {
      .layout-event-stream {
        height: auto;

        .container {
          height: fit-content;

          .layout-header {
            height: auto;

            .layout-header-main {
              flex-direction: column;
              align-items: flex-start;

              .layout-header-main-left {
                .layout-header-img {
                  margin-right: 20px;

                  .layout-header-img-border {
                    display: none;
                  }
                }

                .layout-header-title {
                  p {
                    font-size: 15px;
                  }

                  h2 {
                    font-size: 15px;
                  }
                }
              }

              .layout-header-main-right {
                width: 100%;
                justify-content: space-around;
                flex-direction: row-reverse;

                .layout-header-time {
                  font-size: 20px;
                }

                .location-container {
                  .layout-header-location {
                    margin: 0 auto;
                    @include flex(center, center);
                    flex-direction: row-reverse;
                    font-size: 15px;
                  }

                  .text-color-gray-shade {
                    margin-top: 4px;
                    line-height: 16px;

                  }
                }
              }
            }
          }
        }
      }
    }

    .mobile-layout {
      overflow: scroll;
      flex-grow: 1;
      display: flex;
      flex-direction: column-reverse;
      width: 100%;

      .wrap-add-with-child {
        .layout-children {
          overflow: scroll;
          width: 100%;
          max-width: 100%;
          min-width: 100%;

          .cross-btn {
            display: none;
          }
        }
      }
    }
  }


}

//small laptops
@media (min-width:mediaWight(mobileXl)) and (max-width: mediaWight(desktopSm)) {
  .layout-main {
    flex-direction: column;
    height: 100%;


    .mobile-layout {
      width: 100% !important;
      flex-direction: column-reverse;

      .wrap-add-with-child {

        .layout-children {
          overflow: scroll;
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }

        .cross-btn {
          display: none;
        }
      }
    }

  }
}
