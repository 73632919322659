@import '../../App';

.event-agenda-main {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: $color-white;
  height: 100%;

  .event-agenda-inside {
    position: relative;
    width: 100%;
    display: flex;
    max-height: 100%;
    flex-direction: column;
    .event-agenda-helper-mobile{
      position: absolute;
      top: 0;
      right: 5px;
      @media (min-width: mediaWight(mobileXl)) {
        display: none;
      }

    }
  }

}

.event-agenda-title-main {
  width: 92%;
  padding: 16px;
  background-color: $child-titleColor;
  color: $child-titleTextColor;

  .event-agenda-title {
    width: 100%;
    margin: auto;
    text-align: center;
    @include flex(space-between, center);

    p {
      @include font-style($font-second, fontWeight('bold'), 20px, 28px);
    }
  }
}

.event-agenda-cards-main {
  @include flex(content, center);
  @include font-style($font-second, fontWeight('regular'), 14px, 175%);
  flex-direction: column;
  box-sizing: border-box;
  gap: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  //flex-grow: 1;
  padding: 16px;
  height: auto;
  //max-height: 50vh;
  width: 100%;
}

.relative {
  position: relative;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.event-agenda-card {
  min-height: 90px;
  padding: 16px;
  border-radius: 10px;
  display: flex;
  gap: 16px;
  .border-white{
    border-right: 1px solid white;
  }
  .border-silver{
    border-right: 1px solid  rgba(40, 40, 40, 0.08);
  }
  .agenda-time {
    @include font-style($font-third, fontWeight('semiBold'), 20px, 20px);
    //min-width: 15%;
    width: auto;
    position: relative;
    padding-right: 10px;

    .add-player-icon {
      background: rgba(255, 255, 255, 0.13);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      width: 56px;
      height: 34px;

    }
  }


  div:first-child {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between
  }

  .event-agenda-detail {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .event-agenda-speakers {
    display: flex !important;
    flex-direction: row !important;
    svg:not(:first-child),
    img:not(:first-child) {
      margin-left: -10px;
    }
  }
  .event-agenda-speakers-avatar_text-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;
    overflow: hidden;
    .event-agenda-speakers-avatar_text-half {
      min-width: auto;
      .event-agenda-speakers-placeholder_text,
      .event-agenda-speakers-avatar_text {
        background:#F8F8F8;
        max-width: 100%;
        width: fit-content;
        padding: 3px 4px 3px 4px;
        border-radius: 31px;
        flex-direction: row;
        @include flex(start, center);
        img{
          margin-right: 6px;
        }
      }
      .event-agenda-speakers-placeholder_text{
        p{
          padding-bottom: 2px;
        }
      }
    }
    span{
      margin-top: 10px;
    }

  }

  img {
    border-radius: 50px;
    width: 28px;
    height: 28px;
  }

}
.event-agenda-speakers-placeholder_text-wrap{
  display: flex;
  min-width: auto;
  gap: 8px!important;
  justify-content: flex-start!important;

}
.event-agenda {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 85%;
  cursor: pointer;

  .chair-agenda{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    p {
      color: $color-black-50;
      @include font-style($font-third, fontWeight('regular'), 14px, 17px);
    }
  }

  .title {
    @include font-style($font-main, fontWeight('extraBold'), 18px, 120%)
  }

  .sub-title {
    @include font-style($font-main, fontWeight('meduim'), 16px, 140%);
  }

  .para-text {
    @include more-text;
    @include font-style($font-third, fontWeight('regular'), 14px, 24px);
  }
}
.event-agenda-speakers{
  svg:not(:first-child),
  img:not(:first-child) {
    margin-left: -10px;
  }

  svg,
  img{
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
  svg{
    background: #F8F8F8;
  }
}
.size-event-agenda-speakers-avatar{
  svg,
  img{
    width: 36px!important;
    height: 36px!important;
  }
}
.event-agenda-calendar {
  display: flex;
  gap: 24px;
  justify-content: start;
  padding:20px 40px;
  background:$color-three ;

  .agenda-calendar-inside {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    .agenda-day {
      color: $color-days-name;
      @include font-style($font-third, fontWeight('meduim'), 12px, 12px);
    }

    .agenda-date {
      @include font-style($font-third, fontWeight('semiBold'), 16px, 16px);
      padding: 13px 8px 7px 8px;
      margin-bottom: 5px;
      border-radius: 50%;
      text-align: center;
      cursor: pointer;
      color: $color-date-number;
    }

    .agenda-today {
      background-color: $color-blue;
      color: $color-white
    }
  }
}

@media (max-width: mediaWight(mobileXl)) {
  .event-agenda-title-main {
    display: none;
  }
}

@media (min-width: mediaWight(mobileXl)) and (max-width: mediaWight(desktopSm)) {
  .event-agenda-cards-main {
    .agenda-cards-main {
      width: 98%;
    }
  }
}

@media (min-width: mediaWight(desktopSm)) and (max-width: 1768px) {

  .event-agenda-title-main {
    padding: 12px;
  }


  .event-agenda {
    .title {
      @include font-style($font-title, fontWeight('semiBold'), 20px, 28px)
    }

    .sub-title {
      @include font-style($font-main, fontWeight('meduim'), 14px, 140%);
        color: $color-black;
    }
  }

  .event-agenda-calendar {
    gap: 20px;
    padding: 20px 40px;

    .agenda-calendar-inside {
      .agenda-today {
        background-color: $color-blue;
        color: $color-white;
      }
    }
  }
}
@media screen and (max-width:mediaWight(desktopSm)) {
    .agenda-main-desc-mobile {
      flex-direction: row;
      height: 40%;

      .agenda-card {
        min-width: 400px;
        min-height: 150px;
        height: auto;
      }
    }
}