@import '../../App';

.exhibition-main {
  height: auto;
  width: 80%;
  overflow: scroll;
  margin: 0 auto;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px 20px;
  img {
    height: 100%;
  }

  div:nth-child(n+1) {
    padding: 10px;
    box-sizing: border-box;
    width: 18%;
    height: 150px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:hover {
      box-shadow: 1px 2px 6px $color-black;
      scale: 1.1;
    }
  }

  img {
    object-fit: contain;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .exhibition-main {
    height: 100%;

    div:nth-child(n+1) {
      width: 50%;
    }
  }

}


