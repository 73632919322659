// TODO: #4C6FFF
// TODO: #A1A9BF
// TODO: #F5F5F5
// TODO: #545E67
// TODO: #6B6F7A
// TODO: #384150

$color_one: #f6f6f6;
$color_two: #2c6da5;
$color_three: #b1d338;
$color_four: #afafaf;
$color_five: #515242;
$color_six: #1a1003;

$card_background_1: $color_three;
$card_text_1: $color_four;

$color-play-button-agenda: rgb(35, 94, 231);
$highlighted-agenda: $color-three;
$highlighted-agenda-text-color: rgba(245, 245, 245, 0.7);
$color-hover: rgba(211, 236, 119, 0.75);

$color-name:#384150;
$color-black: $color_five;
$color-black-75: #282828bf;
$color-background: $color-three;
$color-gradient: $color_two;
$color-blue: $color_two;
$color-black-50-rgba: rgba(40, 40, 40, 0.08);
$color-black-50: #28282880;
$color-pill-background: #f0f0f0ff;
$color-black-8: #28282814;
$color-grey-shade: #a1a9bfff;
$color-background-2: #f5f5f5ff;
$color-message-color: #545e67ff;
$color-message: $color_five;
$color-contrast: #6b6f7aff;
$color-name-color: #384150ff;
$color-light-grey-elements: #87878714;
$color-white: #ffffffff;
$color-background-container: $color_three;
$color-cards-background: #f3f4f8ff;
$color-play-button: #235EE714;
$color-silver: #A1A9BF;
$color-line-between-desc: #C4C4C4;

//Global
$arrow-color: $color-white;
$icon-color-sidebar: $color-silver;
$inactive : #6B6F7A;
//SideBar
$color-selected-icon: $color-blue;

//Agenda
$color-days-name: rgba($inactive,0.6);
$color-date-number: $color-white;


@mixin text-style-navbar-heading{
  font-size: 22px;
  font-family: "Helvetica Neue";
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-chat-username {
  font-size: 16px;
  font-family: "Helvetica Neue";
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-messages-comments {
  font-size: 16px;
  font-family: "Helvetica Neue";
  font-weight: 400;
  font-style: normal;
  line-height: 175%;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-navbar-heading {
  font-size: 22px;
  font-family: "Helvetica Neue";
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-chat-username {
  font-size: 16px;
  font-family: "Helvetica Neue";
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-messages-comments {
  font-size: 16px;
  font-family: "Helvetica Neue";
  font-weight: 400;
  font-style: normal;
  line-height: 175%;
  text-decoration: none;
  text-transform: none;
}


//color variables
//$whiteColor : #fff;
//$button-gradient: linear-gradient(
//  90.98deg,
//  #ff68c3 0%,
//  #ff7474 29.69%,
//  #5771f9 100%
//);
$ratio-gradient: linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(76, 111, 255, 0.24) 100%);
$child-titleColor: $color-background;


//$wtt-red: #d72864;
//$wtt-dark-red: #8c195f;
//$gallus-blue: rgb(76, 111, 255);
//$gallus-silver:  #F3F4F8;
$button-disabled-color: $color-cards-background;
$text-color-title: $color-black;

//$child-titleColor : $wtt-dark-red;
$child-titleTextColor: black;
//$agenda-color: $gallus-silver;
$poll-color: $color-blue;
$button-disabled-color: rgba(40, 40, 40, 0.08);
$button-enabled-color: $color-blue;
$button-text-color: $color-white;


$font-main: 'Roboto', serif;
$font-third: 'Inter', serif;
$font-second: 'Open Sans', sans-serif;
$font-third:'Roboto',sans-serif;
$font-desc: 'Helvetica',sans-serif;
$font-title: 'Felix',sans-serif;
$font-placeholder: 'Outfit',sans-serif;


$primary_main: #8c195f;
$primary_light: #bf4e8c;
$primary_dark: #5a0035;

$secondary_main: #d72864;
$secondary_light: #ff6391;
$secondary_dark: #a0003a;

$colors: (
        highlighted_agenda_text_color : $highlighted-agenda-text-color,
        color_black: $color-black,
        color_white: $color-white,
        color_silver_icons: $color-silver,
);

:export {
  //primary: $wtt-dark-red;
  //secondary: $wtt-red;
  primary_main: $primary_main;
  primary_light: $primary_light;
  primary_dark: $primary_dark;

  secondary_main: $secondary_main;
  secondary_light: $secondary_light;
  secondary_dark: $secondary_dark;

  highlighted_agenda_text_color: $color-blue;
  highlighted_agenda: $highlighted-agenda;
  color_hover: $color-hover;
  color_black: $color-black;
  color_black-75: $color-black-75;
  color_background: $color-background;
  color_gradient: $color-gradient;
  color_blue: $color-blue;
  color_black_50: $color-black-50;
  color_pill_background: $color-pill-background;
  color_black_8: $color-black-8;
  color_grey_shade: $color-grey-shade;
  color_background_2: $color-background-2;
  color_message_color: $color-message-color;
  color_message: $color-message;
  color_contrast: $color-contrast;
  color_name_color: $color-name-color;
  color_light_grey_elements: $color-light-grey-elements;
  color_white: $color-white;
  color_background_container: $color-background-container;
  color_cards_background: $color-cards-background;
  color_silver_icons: $color-silver;
  color_play_button: $color-play-button-agenda;
  color_play_button_bg: $color-play-button;
  //button_gradient: $button-gradient;
  icon_color_sidebar: $icon-color-sidebar;
  color_selected_icon: $color-selected-icon;


  font_main: $font-main;
  font_second: $font-second;
  font_third: $font-third;
  font_main_temporary: "Helvetica Neue", sans-serif;
}


