@import '../../../App';


.chat-main-private {
  padding-top: 15px;
  height: calc(90% - #{$wight-title-layout-children});
}

.chat-main-public {
  height: calc(90% - #{$wight-title-layout-children} - 48px );
}
.chat-main-public-hotline{
  height: calc(90% - #{$wight-title-layout-children});
  padding-top: 15px;
  position: relative;
  .chat-main-public-hotline-helper-mobile{
    right: 5px;
    top: 5px;
    position: absolute;
    @media (min-width: mediaWight(mobileXl)) {
      display: none;
    }
  }

}
.chat-main-public-hotline,
.chat-main-public,
.chat-main-private {
  overflow-y: scroll;
  //min-height: 400px;
  box-sizing: border-box;
  padding-top: 15px;

  //max-height: 800px;


  .msg-div {
    display: flex;
    flex-direction: column;
    margin: 0 5px;
    padding-top: 5px;

    .msg {
      line-break: anywhere;
      display: flex;
      width: fit-content;
      position: relative;
    }

    .my-msg {
      justify-content: right;
      background: $color-blue;
      border-radius: 12px 2px 12px 12px;
      margin: 0 29px 24px 24px;

      color: #F1F1F1;
    }

    .not-my-msg {
      background: $color-cards-background;
      margin: -25px 24px 24px 49px;

      border-radius: 2px 12px 12px 12px;

    }
    .admin-msg{
      background: yellow;
    }

    h4 {
      color: $color-name-color;
      align-self: flex-start;
    }

    .msg-time {
      display: flex;
      gap: 7px;
      position: relative;


      p {
        color: $color-contrast;
        align-self: flex-start;

      }

      margin-bottom: 5px;
    }
  }


}

.chat-header {
  z-index: 2;
  position: relative;
  top: 0;
  height: 48px;
  padding: 14px;
  background-color: $color-background;
  display: flex;
  gap: 20px;
  align-items: center;


  .user-detail-chat {
    @include flex(space-between, none, column);
    padding-top: 5px;
  }

  .user-online {
    background: rgba(18, 183, 106, 0.08);
  }

  .user-status {
    width: 64px;
    margin-left: 8px;
    height: 20px;
    border-radius: 12px;
    font-size: 12px;
    color: #149057;
    @include flex(none, center);
    gap: 6px;
    padding-top: 2px;
    box-sizing: border-box;

    .status-circle {
      width: 7px;
      height: 7px;
      margin-left: 5px;
      margin-bottom: 2px;
      border-radius: 3.5px;
    }

    .status-circle-online {
      background: #149057;
    }

    .status-circle-offline {
      background: silver;
    }
  }
}

.send-msg {
  @include flex(space-between,center);
  //align-content: center;
  gap: 5px;
  background: $color-cards-background;
  position: relative;
  border-radius: 100px;
  margin: 0 10px;
  padding: 16px 16px 16px 12px;
}

.send-msg-input-flex-center {
  @include flex(none, center);
  position: relative;
}

.delete-file-chat-icon {
  position: relative;
  bottom: 20px;
}

.file-chose-chat-icon:hover,
.delete-file-chat-icon:hover {
  cursor: pointer;
}

.form-chat {
  height: 10%;
  width: 100%;
  position: relative;
  background: $color-white;
}
@media (max-width: mediaWight(desktopSm)) {

  .chat-main-private{
    height: 50vh;
    .msg-div{
      gap: 0;

      .not-my-msg{
        margin-left: 50px;
      }

    }
  }
  .chat-main-public{
    height: calc(90% - 87px );
    //margin-bottom: 60px;
    //height: 50vh;
    .msg-div{
      gap: 0;

      .not-my-msg{
        margin-left: 50px;
      }

    }
  }
  .chat-main-public-hotline{
    height: 76vh;
  }
}

@media (min-width:  mediaWight(mobileXl)) and (max-width:  mediaWight(desktopSm)) {
  .form-chat {
    width: 100%;
    //bottom: 63px;

  }
}

@media (max-width: mediaWight(mobileXl)) {
  .form-chat {
    width: 100%;
    //bottom: 63px;
  }
  .chat-main-private{
    height: 50vh;
  }
}

