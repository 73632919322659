@import "../../App";

.sidebar-container {
  background-color: $color-background-2;
  min-width: 78px;
  height: 100%;
  width: 78px;
  @include flex(center, center);
  flex-direction: column;
  gap: 48px;
  overflow: hidden;
}

.sidebar-icon {
  @include flex(center, center);
  padding: 10px;
  width: 86%;
  cursor: pointer;
  box-sizing: border-box;
  color: $color-blue;
  gap: 5px;

  &:hover {
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.1) 0 8px 24px;
  }

  .sidebar-icon-text {
    display: none;
    padding: 5px;
  }
}

.title-main {

  .title {
    width: 100%;
    text-align: center;
    @include flex_nojustify(center);
    @include font-style($font-main, 800, 24px, 28px);
  }
}


@media (min-width: mediaWight(desktopSm)) {
  .border-side-bar {
    border-right: 2px solid $color-blue;
  }
}

@media (min-width: mediaWight(desktopSm)) and (max-width: mediaWight(desktopXl)) {
  .sidebar-container {
    width: 78px;
    gap: 36px;

    svg {
      width: 18px;
    }
  }

    .title-main {
    padding: 19px 16px 16px;


    .title {
      @include font-style($font-second, 700, 20px, 26px);
    }
  }

}

@media (max-width: mediaWight(desktopSm)) {
  .sidebar-container {
    overflow: scroll;
    background-color: $color-background-container;
    min-width: 100% !important;
    margin: 0 auto;
    //width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    padding: 0 20px;
    gap: 2px;
    justify-content: flex-start;
    height: 68px !important;
  }

  .sidebar-icon {
    padding: 8px 10px;
    background: #ECF0FC;
    border-radius: 20px;
    width: fit-content;

    &:hover {
      background-color: #ECF0FC;
      box-shadow: none;
    }
  }

  .sidebar-icon-text {
    display: block !important;
    @include font-style($font-main, 500, 14px, 15px)
  }
}

@media (min-width: mediaWight(mobileMd)) {
  .sidebar-container {
    justify-content: center;
  }
}